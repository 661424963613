import { navigate } from "gatsby"
import React from "react"
import { useHistoryContext } from "../../../context/history"
import useLongPress from "../../../hooks/useLongPress"

type Props = React.PropsWithChildren<React.SVGProps<SVGGElement>>

const Block: React.FC<Props> = ({ children, ...delegated }) => {
  const history = useHistoryContext()

  const { handlers } = useLongPress<SVGGElement>({
    onClick: event => {
      const link = event.currentTarget.getAttribute("data-href")
      if (!link) return

      navigate(link, { state: { forward: true } })
      history.push(link)
    },
  })

  return (
    <g role="link" {...delegated} {...handlers}>
      {children}
    </g>
  )
}

export default Block
