import React from "react"

const Lines: React.FC = () => (
  <>
    <g id="net" className="line-group">
      <path
        id="all--line"
        d="M769.821 539.14L769.11 539.85L766.56 537.3L766.5 624.75H141.83V424L139.22 426.6L138.52 425.89L142.35 422.07L146.15 425.868L145.44 426.58L142.83 423.959V623.75H453.5V341.21H398.84V340.21H643.37L640.799 337.64L641.51 336.93L645.331 340.76L641.53 344.57L640.821 343.86L643.469 341.21H454.5V623.75H765.5L765.56 537.198L762.889 539.869L762.18 539.158L766.01 535.33L769.821 539.14Z"
        fill="#FFF9F7"
      />
      <path
        id="obraz_i_wiedza--line"
        d="M395.83 340.21V341.21H311.83V227.52L262.36 194.64L263.09 198.26L262.11 198.448L261.04 193.15L266.32 192.08L266.51 193.07L262.91 193.79L312.83 226.98V340.21H395.83Z"
        fill="#FFF9F7"
      />
    </g>
    <g id="w_humanistyce--niepewnosc_i_relatywizacja" className="line-group">
      <path
        id="niepewnosc_i_relatywizacja--line"
        d="M908.17 447.53L888.229 447.5L890.799 450.08L890.101 450.78L886.29 446.98L890.12 443.15L890.831 443.86L888.19 446.5L907.17 446.53L907.229 284.53H398.851V283.53H908.229L908.17 447.53Z"
        fill="#FFF9F7"
      />
      <path
        id="line--line"
        d="M395.84 283.531H313.837V284.531H395.84V283.531Z"
        fill="#FFF9F7"
      />
      <path
        id="w_humanistyce--line"
        d="M310.83 283.53V284.53H226.85L226.83 250.43L224.22 253.04L223.52 252.33L227.35 248.5L231.15 252.3L230.44 253.01L227.83 250.39L227.85 283.53H310.83Z"
        fill="#FFF9F7"
      />
    </g>
    <g id="jezyk_wizualny--wizualny_alfabetyzm" className="line-group">
      <path
        id="wizualny_alfabetyzm--line"
        d="M316.13 482.39L315.42 483.1L312.88 480.55V568H143.84V567H311.88V480.448L309.2 483.119L308.5 482.408L312.33 478.58L316.13 482.39Z"
        fill="#FFF9F7"
      />
      <path
        id="jezyk_wizualny--line"
        d="M765.56 88.381V1.06H1.06V567H140.83V568H0.0599976V0.0599976H766.56V88.381H765.56Z"
        fill="#FFF9F7"
      />
      <path
        id="jezyk_wizualny--arrow"
        d="M762.892 84.631L766.012 87.752L769.108 84.654L769.815 85.361L766.012 89.166L762.185 85.338L762.892 84.631Z"
        fill="#FFF9F7"
      />
    </g>
    <g id="niepewnosc_i_relatywizacja--wzglednosc_czasu" className="line-group">
      <path
        id="niepewnosc_i_relatywizacja--line_2"
        d="M908.17 460.75V568H767.54V567H907.17V461.75H888.18L887.75 461.32V461.24L887.709 461.28L887.75 461.32V461.75H888.18L890.799 464.379L890.101 465.08L886.29 461.28L890.12 457.448L890.831 458.158L888.241 460.75H908.17Z"
        fill="#FFF9F7"
      />
      <path
        id="wzglednosc_czasu--line"
        d="M764.53 568H718.241L720.851 570.61L720.139 571.32L716.331 567.51L720.161 563.68L720.87 564.39L718.26 567H764.53V568Z"
        fill="#FFF9F7"
      />
    </g>
    <g id="model--w_rysunku" className="line-group">
      <path
        id="w_rysunku--line"
        d="M140.84 453.5V454.5L56.83 454.51V193.93L54.22 196.54L53.52 195.83L57.35 192L61.15 195.8L60.44 196.51L57.83 193.89V453.51L140.84 453.5Z"
        fill="#FFF9F7"
      />
      <path
        id="model--line"
        d="M192 453.99L188.17 457.82L187.46 457.11L190.08 454.5H143.84V453.5H190.1L187.49 450.89L188.2 450.18L192 453.99Z"
        fill="#FFF9F7"
      />
    </g>
    <path
      id="historia--isotype"
      d="M145.49 314.96L146.19 315.67L142.37 319.5L138.56 315.698L139.27 314.99L141.84 317.56V250.46L139.27 253.04L138.56 252.33L142.39 248.5L146.19 252.3L145.49 253.01L142.84 250.36V317.62L145.49 314.96Z"
      fill="#FFF9F7"
    />
    <path
      id="architektura_informacji--porzadek_w_typografii"
      d="M475.34 170.74L471.51 174.57L470.799 173.86L473.411 171.25H349.74L352.351 173.86L351.64 174.57L347.83 170.76L351.66 166.93L352.37 167.64L349.76 170.25H473.43L470.831 167.64L471.53 166.93L475.34 170.74Z"
      fill="#FFF9F7"
    />
    <path
      id="czytelnosc_czytalnosc--recepcja_i_percepcja"
      d="M560.331 227.24L556.51 231.07L555.799 230.36L558.379 227.78L397.851 227.75L397.83 370.629L400.47 367.99L401.18 368.698L397.37 372.5L393.54 368.67L394.25 367.959L396.83 370.55L396.851 226.75L558.459 226.78L555.821 224.14L556.53 223.43L560.331 227.24Z"
      fill="#FFF9F7"
    />
    <line
      id="struktury_wizualne--czas"
      x1="681.5"
      y1="356"
      x2="681.5"
      y2="385"
      stroke="#FFF9F7"
    />
    <line
      id="czas--czas_jako_przestrzen"
      x1="681.5"
      y1="409"
      x2="681.5"
      y2="438"
      stroke="#FFF9F7"
    />
    <line
      id="czas_jako_przestrzen--os_czasu"
      x1="681.5"
      y1="467"
      x2="681.5"
      y2="496"
      stroke="#FFF9F7"
    />
    <line
      id="os_czasu--wzglednosc_czasu"
      x1="681.5"
      y1="524"
      x2="681.5"
      y2="553"
      stroke="#FFF9F7"
    />
    <line
      id="zbiory--graficzne__definicje"
      x1="595.5"
      y1="524"
      x2="595.5"
      y2="553"
      stroke="#FFF9F7"
    />
    <line
      id="atlas--esej_wizualny"
      x1="510.5"
      y1="524"
      x2="510.5"
      y2="553"
      stroke="#FFF9F7"
    />
    <line
      id="mechanizmy_porzodkowania--zbiory"
      x1="595.5"
      y1="469"
      x2="595.5"
      y2="498"
      stroke="#FFF9F7"
    />
    <line
      id="klasyfikowanie_i_definiowanie--mechanizmy_porzodkowania"
      x1="595.5"
      y1="411"
      x2="595.5"
      y2="440"
      stroke="#FFF9F7"
    />
    <line
      id="przesrzen--mapowanie"
      x1="766.5"
      y1="411"
      x2="766.5"
      y2="440"
      stroke="#FFF9F7"
    />
    <line
      id="mapowanie--konwencje_wizualne"
      x1="766.5"
      y1="466"
      x2="766.5"
      y2="495"
      stroke="#FFF9F7"
    />
    <line
      id="mapa_mentalna--mapa_jako_obraz_swiata"
      x1="851.5"
      y1="351"
      x2="851.5"
      y2="380"
      stroke="#FFF9F7"
    />
    <line
      id="obraz_i_wiedza--historia"
      x1="202.28"
      y1="187.414"
      x2="162.28"
      y2="214.414"
      stroke="#FFF9F7"
    />
    <line
      id="model--funkcje__obrazu"
      x1="85"
      y1="170.5"
      x2="115"
      y2="170.5"
      stroke="#FFF9F7"
    />
    <line
      id="funkcje__obrazu--obraz_i_wiedza"
      x1="170"
      y1="170.5"
      x2="200"
      y2="170.5"
      stroke="#FFF9F7"
    />
    <line
      id="obraz_i_wiedza--architektura_informacji"
      x1="255"
      y1="170.5"
      x2="285"
      y2="170.5"
      stroke="#FFF9F7"
    />
    <line
      id="metafora--model"
      x1="122.279"
      y1="127.415"
      x2="76.2794"
      y2="158.415"
      stroke="#FFF9F7"
    />
    <line
      id="kultura_wizualna_wspolczesnie--projektowanie_graficzne"
      x1="340"
      y1="114.5"
      x2="369"
      y2="114.5"
      stroke="#FFF9F7"
    />
    <line
      id="obrazy_i_komunikacja--kultura_wizualna_wspolczesnie"
      x1="250.277"
      y1="72.584"
      x2="286.277"
      y2="96.584"
      stroke="#FFF9F7"
    />
    <line
      id="obrazy_i_komunikacja--schemat_diagram_wykres_infografika"
      x1="227.5"
      y1="90"
      x2="227.5"
      y2="72"
      stroke="#FFF9F7"
    />
    <line
      id="schemat_diagram_wykres_infografika--obraz_i_wiedza"
      x1="227.5"
      y1="154"
      x2="227.5"
      y2="138"
      stroke="#FFF9F7"
    />
    <line
      id="obraz_i_wiedza--w_humanistyce"
      x1="227.5"
      y1="214"
      x2="227.5"
      y2="188"
      stroke="#FFF9F7"
    />
    <line
      id="obrazy_i_komunikacja--metafora"
      x1="162.72"
      y1="100.585"
      x2="205.72"
      y2="71.5855"
      stroke="#FFF9F7"
    />
    <line
      id="isotype--poszukiwanie_formy"
      x1="142.5"
      y1="354"
      x2="142.5"
      y2="381"
      stroke="#FFF9F7"
    />
    <line
      id="widzenie_jako_myslenie--wizualny_alfabetyzm"
      x1="312.5"
      y1="411"
      x2="312.5"
      y2="438"
      stroke="#FFF9F7"
    />
    <line
      id="w_rysunku--notowanie"
      x1="227.5"
      y1="468"
      x2="227.5"
      y2="497"
      stroke="#FFF9F7"
    />
    <line
      id="poszukiwanie_formy--analiza_i_synteza"
      x1="170"
      y1="397.5"
      x2="200"
      y2="397.5"
      stroke="#FFF9F7"
    />
    <line
      id="analiza_i_synteza--widzenie_jako_myslenie"
      x1="255"
      y1="397.5"
      x2="285"
      y2="397.5"
      stroke="#FFF9F7"
    />
    <line
      id="w_rysunku--wizualny_alfabetyzm"
      x1="255"
      y1="454.5"
      x2="285"
      y2="454.5"
      stroke="#FFF9F7"
    />
    <line
      id="widzenie_jako_myslenie--recepcja_i_percepcja"
      x1="340"
      y1="397.5"
      x2="370"
      y2="397.5"
      stroke="#FFF9F7"
    />
    <line
      id="analiza_i_synteza--wizualny-alfabetyzm"
      x1="248.277"
      y1="411.584"
      x2="290.277"
      y2="439.584"
      stroke="#FFF9F7"
    />
    <line
      id="widzenie_jako_myslenie--mysl_jako_obraz_i_slowo"
      x1="334.277"
      y1="411.584"
      x2="376.277"
      y2="439.584"
      stroke="#FFF9F7"
    />
    <line
      id="tekst--linia"
      x1="538"
      y1="57.5"
      x2="568"
      y2="57.5"
      stroke="#FFF9F7"
    />
    <line
      id="linia--obraz"
      x1="624"
      y1="57.5"
      x2="654"
      y2="57.5"
      stroke="#FFF9F7"
    />
    <line
      id="notacja--tekst_vs_obraz"
      x1="539"
      y1="113.5"
      x2="569"
      y2="113.5"
      stroke="#FFF9F7"
    />
    <line
      id="porzadek_w_typografii--interfejs"
      x1="538"
      y1="170.5"
      x2="568"
      y2="170.5"
      stroke="#FFF9F7"
    />
    <line
      id="interfejs--ksiazka_jako_przedmiot"
      x1="624"
      y1="170.5"
      x2="654"
      y2="170.5"
      stroke="#FFF9F7"
    />
    <line
      id="czyelnos&#196;&#135;_czytalnosc--ewolucja_czytania"
      x1="623"
      y1="227.5"
      x2="653"
      y2="227.5"
      stroke="#FFF9F7"
    />
    <line
      id="klasyfikowanie_i_definiowanie--czas"
      x1="623"
      y1="397.5"
      x2="653"
      y2="397.5"
      stroke="#FFF9F7"
    />
    <line
      id="czas--przestrzen"
      x1="709"
      y1="397.5"
      x2="739"
      y2="397.5"
      stroke="#FFF9F7"
    />
    <line
      id="siec--mechanizmy_porzodkowania"
      x1="539"
      y1="453.5"
      x2="569"
      y2="453.5"
      stroke="#FFF9F7"
    />
    <line
      id="mapowanie--niepewnosc_i_relatywizacja"
      x1="793"
      y1="453.5"
      x2="823"
      y2="453.5"
      stroke="#FFF9F7"
    />
    <line
      id="graficzne_definicje--wzglednosc_czasu"
      x1="624"
      y1="567.5"
      x2="654"
      y2="567.5"
      stroke="#FFF9F7"
    />
    <line
      id="tekst_vs_obraz--interfejs"
      x1="595.5"
      y1="131"
      x2="595.5"
      y2="156"
      stroke="#FFF9F7"
    />
    <line
      id="interfejs--czytelnosc_czytalnosc"
      x1="595.5"
      y1="184"
      x2="595.5"
      y2="209"
      stroke="#FFF9F7"
    />
    <line
      id="tekst--tekst_vs_obraz"
      x1="530.277"
      y1="70.584"
      x2="572.277"
      y2="98.584"
      stroke="#FFF9F7"
    />
    <line
      id="porzadek_w_typografii--czytelnosc_czytalnosc"
      x1="530.277"
      y1="183.584"
      x2="572.277"
      y2="211.584"
      stroke="#FFF9F7"
    />
    <line
      id="obraz--jezyk_wizualny"
      x1="701.277"
      y1="70.584"
      x2="743.277"
      y2="98.584"
      stroke="#FFF9F7"
    />
    <line
      id="obraz--tekst_vs_obraz"
      y1="-0.5"
      x2="50.4777"
      y2="-0.5"
      transform="matrix(0.83205 -0.5547 -0.5547 -0.83205 617 99)"
      stroke="#FFF9F7"
    />
    <line
      id="ksi&#196;&#133;zka_jako_przedmiot--czytelnosc_czytalnosc"
      y1="-0.5"
      x2="50.4777"
      y2="-0.5"
      transform="matrix(0.83205 -0.5547 -0.5547 -0.83205 612 216)"
      stroke="#FFF9F7"
    />
    <line
      id="struktury_wizualne--klasyfikowanie_i_definiowanie"
      x1="613.723"
      y1="385.584"
      x2="661.723"
      y2="353.584"
      stroke="#FFF9F7"
    />
    <line
      id="mechanizmy_porzodkowania--atlas"
      x1="523.723"
      y1="501.584"
      x2="571.723"
      y2="469.584"
      stroke="#FFF9F7"
    />
    <line
      id="przestrzen--czas_jako_przestrzen"
      x1="704.723"
      y1="437.584"
      x2="752.723"
      y2="405.584"
      stroke="#FFF9F7"
    />
    <line
      id="mapa_jako_obraz_swiata--mapowanie"
      x1="781.723"
      y1="443.584"
      x2="829.723"
      y2="411.584"
      stroke="#FFF9F7"
    />
    <line
      id="mapowanie--mapa_i_narracja"
      y1="-0.5"
      x2="57.6888"
      y2="-0.5"
      transform="matrix(0.83205 0.5547 0.5547 -0.83205 785 466)"
      stroke="#FFF9F7"
    />
    <line
      id="struktury_wizualne--przestrzen"
      y1="-0.5"
      x2="57.6888"
      y2="-0.5"
      transform="matrix(0.83205 0.5547 0.5547 -0.83205 701 353)"
      stroke="#FFF9F7"
    />
  </>
)

export default Lines
