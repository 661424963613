import React from "react"

export type UseLongPressParams<T> = {
  delay?: number
  onClick?: (e: React.MouseEvent<T> | React.TouchEvent<T>) => void
  onLongPress?: (e: React.MouseEvent<T> | React.TouchEvent<T>) => void
}

const useLongPress = <T>({
  delay,
  onClick,
  onLongPress,
}: UseLongPressParams<T> = {}) => {
  const [action, setAction] = React.useState<"click" | "longpress">()

  const timerRef = React.useRef<NodeJS.Timeout>()
  const isLongPress = React.useRef<boolean>()
  const isTouchMoveInProgress = React.useRef(false)

  const startPressTimer = () => {
    isLongPress.current = false
    timerRef.current = setTimeout(() => {
      isLongPress.current = true
      setAction("longpress")
    }, delay ?? 100)
  }

  const handleOnClick = (e: React.MouseEvent<T>) => {
    if (isLongPress.current) {
      if (typeof onLongPress === "function") onLongPress(e)
      return
    }

    setAction("click")
    if (typeof onClick === "function") onClick(e)
  }

  const handleOnMouseDown = () => {
    startPressTimer()
  }

  const handleOnMouseUp = () => {
    clearTimeout(timerRef.current)
  }

  const handleOnTouchStart = () => {
    if (isTouchMoveInProgress.current) return

    isTouchMoveInProgress.current = true
    startPressTimer()
  }

  const cleanupTouch = (e: React.TouchEvent<T>) => {
    clearTimeout(timerRef.current)
    isTouchMoveInProgress.current = false
    isLongPress.current = false
  }

  const handleOnTouchEnd = (e: React.TouchEvent<T>) => {
    const el = e.currentTarget as unknown as Element
    const klass = "active-link"

    if (isLongPress.current) {
      if (typeof onLongPress === "function") onLongPress(e)
      cleanupTouch(e)
      return
    }

    el.classList.add(klass)
    if (typeof onClick === "function") onClick(e)
    cleanupTouch(e)
  }

  return {
    action,
    handlers: {
      onClick: handleOnClick,
      onMouseDown: handleOnMouseDown,
      onMouseUp: handleOnMouseUp,
      onTouchStart: handleOnTouchStart,
      onTouchMove: handleOnTouchStart,
      onTouchEnd: handleOnTouchEnd,
    },
  }
}

export default useLongPress
