import { between, up } from "styled-breakpoints"
import styled from "styled-components"

export const MapContainer = styled.div`
  width: 100vw;
  height: calc(100vh - var(--header-height));
  overflow: hidden;
  position: relative;

  & * {
    font-family: ${({ theme }) => theme.fonts.azoSans};
  }

  & {
    cursor: move;
  }

  & g#mapa g:not(.dashed-box) {
    & path:last-of-type,
    & text {
      transition: fill 200ms ease-out;
      cursor: pointer;
    }

    &:hover,
    &.active-link {
      & path:last-of-type {
        fill: ${({ theme }) => theme.colors.ivoryWhite};
      }

      & text {
        fill: ${({ theme }) => theme.colors.blue};
      }

      & path.double-border {
        stroke-width: 2;
      }
    }
  }
`

export const MapToolbar = styled.div`
  position: absolute;
  z-index: 9;

  top: 3rem;
  right: 1.8rem;

  ${between("md", "xl")} {
    right: 3.5rem;
    top: 4rem;
  }

  ${up("xl")} {
    right: 6.5rem;
    top: 5rem;
  }

  ${up("xxl")} {
    right: 14rem;
    top: 6rem;
  }

  display: flex;
  flex-direction: column;
  gap: 1.2rem;
`

export const RelativeContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  background: ${({ theme }) => theme.colors.blue};
`

export const ZoomButton = styled.button`
  border: solid 1px ${({ theme }) => theme.colors.ivoryWhite};
  text-align: center;
  font-family: ${({ theme }) => theme.fonts.azoSans};
  background-color: ${({ theme }) => theme.colors.blue};
  cursor: pointer;
  transition: background-color 0.4s ease;

  &:hover {
    background-color: ${({ theme }) => theme.colors.white};
  }

  height: 4.5rem;
  width: 4.5rem;

  ${between("md", "xl")} {
    height: 3rem;
    width: 3rem;
  }

  ${up("xl")} {
    height: 4.5rem;
    width: 4.5rem;
  }

  ${up("xxl")} {
    height: 5rem;
    width: 5rem;
  }

  &:disabled {
    &:hover {
      background-color: ${({ theme }) => theme.colors.blue};
      border-color: #66cbff;

      &:before,
      &:after {
        background-color: #66cbff;
      }
    }
  }
`
export const ZoomInButton = styled(ZoomButton)`
  position: relative;

  &:before {
    position: absolute;
    content: "";
    height: 0.2rem;
    width: 60%;
    background-color: white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: background-color 0.4s ease;
  }

  &:after {
    position: absolute;
    content: "";
    height: 60%;
    width: 0.2rem;
    background-color: white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: background-color 0.4s ease;
  }

  &:hover {
    &:before,
    &:after {
      background-color: ${({ theme }) => theme.colors.blue};
    }
  }
`
export const ZoomOutButton = styled(ZoomButton)`
  position: relative;

  &:before {
    position: absolute;
    content: "";
    height: 0.2rem;
    width: 60%;
    background-color: white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: background-color 0.4s ease;
  }

  &:hover {
    &:before {
      background-color: ${({ theme }) => theme.colors.blue};
    }
  }
`
