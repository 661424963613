import { PageProps } from "gatsby"
import React, { useState } from "react"
import ContinueLink from "../components/continue-link"
import AdvancedHeader from "../components/header/advanced"
import Seo from "../components/seo"
import * as Styled from "../styles/pages/map"
import GlobalStyles from "../styles/globals"
import { useRefEffect } from "../hooks/useRefEffect"
import panzoom, { PanZoom } from "panzoom"
import Lines from "../components/map/lines"
import Blocks from "../components/map/blocks"

const MapPage: React.FC<PageProps> = () => {
  const [instance, setInstance] = useState<PanZoom | undefined>(undefined)

  const [_, setRef] = useRefEffect<SVGGElement>(node => {
    const panzoomInstance = panzoom(node, {
      maxZoom: 4,
      minZoom: 0.5,
      autocenter: true,
      bounds: true,
      boundsPadding: 0.1,
    })

    setInstance(panzoomInstance)
  }, [])

  return (
    <>
      <Seo title="Mapa tekstu" />
      <GlobalStyles variant="blue" />
      <AdvancedHeader progress={<ContinueLink />} divider />
      <Styled.MapContainer>
        <Styled.RelativeContainer>
          <Styled.MapToolbar role="toolbar" aria-label="Kontrolki przybliżenia">
            <Styled.ZoomInButton
              type="button"
              title="Zoom In"
              onClick={() =>
                instance?.smoothZoom(
                  document.body.clientWidth / 2,
                  document.body.clientHeight / 2,
                  1.5
                )
              }
            />
            <Styled.ZoomOutButton
              type="button"
              title="Zoom Out"
              onClick={() =>
                instance?.smoothZoom(
                  document.body.clientWidth / 2,
                  document.body.clientHeight / 2,
                  0.5
                )
              }
            />
          </Styled.MapToolbar>

          <svg fill="#5CCEFF" width="100%" height="100%" viewBox="0 0 909 625">
            <g x={100} y={100} id="mapa" ref={setRef}>
              <Lines />
              <Blocks />
            </g>
          </svg>
        </Styled.RelativeContainer>
      </Styled.MapContainer>
    </>
  )
}

export default MapPage
