import React from "react"
import Block from "../block"

const Blocks: React.FC = () => {
  return (
    <>
      <g id="obrazy_i_komunikacja" className="dashed-box">
        <path id="ramka" d="M257 73H197V41H257V73Z" fill="#00A8FF" />
        <path
          id="ramka_2"
          d="M256 72H198.5V42.5H256V72Z"
          fill="#00A8FF"
          stroke="#FFF9F7"
          strokeDasharray="1 1"
        />
        <text
          id="obrazy i komunikacja"
          fill="#FFF9F7"
          xmlSpace="preserve"
          style={{ whiteSpace: "pre" }}
          fontSize="6"
          fontWeight="600"
          letterSpacing="0em"
        >
          <tspan x="216.71" y="55.1909">
            obrazy&#10;
          </tspan>
          <tspan x="206.934" y="62.1909">
            i komunikacja
          </tspan>
        </text>
      </g>
      <g id="tekst" className="dashed-box">
        <path id="ramka_3" d="M540 72H482V42H540V72Z" fill="#00A8FF" />
        <path
          id="ramka_4"
          d="M539.033 71.0625H483.45V43.4062H539.033V71.0625Z"
          fill="#00A8FF"
          stroke="#FFF9F7"
          strokeDasharray="1 1"
        />
        <text
          id="tekst_2"
          fill="#FFF9F7"
          xmlSpace="preserve"
          style={{ whiteSpace: "pre" }}
          fontSize="6"
          fontWeight="600"
          letterSpacing="0em"
        >
          <tspan x="503.635" y="59.2216">
            tekst
          </tspan>
        </text>
      </g>
      <g id="struktury_wizualne" className="dashed-box">
        <path id="ramka_5" d="M710 355H652V326H710V355Z" fill="#00A8FF" />
        <path
          id="ramka_6"
          d="M709.033 354.094H653.45V327.359H709.033V354.094Z"
          fill="#00A8FF"
          stroke="#FFF9F7"
          strokeDasharray="1 1"
        />
        <text
          id="struktury wizualne"
          fill="#FFF9F7"
          xmlSpace="preserve"
          style={{ whiteSpace: "pre" }}
          fontSize="6"
          fontWeight="600"
          letterSpacing="0em"
        >
          <tspan x="667.589" y="339.487">
            struktury{" "}
          </tspan>
          <tspan x="668.116" y="346.487">
            wizualne
          </tspan>
        </text>
      </g>
      <g id="czas" className="dashed-box">
        <path id="ramka_7" d="M710 412H652V383H710V412Z" fill="#00A8FF" />
        <path
          id="ramka_8"
          d="M709.033 411.094H653.45V384.359H709.033V411.094Z"
          fill="#00A8FF"
          stroke="#FFF9F7"
          strokeDasharray="1 1"
        />
        <text
          id="czas_2"
          fill="#FFF9F7"
          xmlSpace="preserve"
          style={{ whiteSpace: "pre" }}
          fontSize="6"
          fontWeight="600"
          letterSpacing="0em"
        >
          <tspan x="674.245" y="399.987">
            czas
          </tspan>
        </text>
      </g>
      <g id="przestrzen" className="dashed-box">
        <path id="ramka_9" d="M795 412H737V383H795V412Z" fill="#00A8FF" />
        <path
          id="ramka_10"
          d="M794.033 411.094H738.45V384.359H794.033V411.094Z"
          fill="#00A8FF"
          stroke="#FFF9F7"
          strokeDasharray="1 1"
        />
        <text
          id="przestrzen_2"
          fill="#FFF9F7"
          xmlSpace="preserve"
          style={{ whiteSpace: "pre" }}
          fontSize="6"
          fontWeight="600"
          letterSpacing="0em"
        >
          <tspan x="750.33" y="399.987">
            przestrze&#x144;
          </tspan>
        </text>
      </g>
      <g id="klasyfikowanie_i_definiowanie" className="dashed-box">
        <path id="ramka_11" d="M624 412H566V383H624V412Z" fill="#00A8FF" />
        <path
          id="ramka_12"
          d="M623.033 411.094H567.45V384.359H623.033V411.094Z"
          fill="#00A8FF"
          stroke="#FFF9F7"
          strokeDasharray="1 1"
        />
        <text
          id="klasyfikowanie i definiowanie"
          fill="#FFF9F7"
          xmlSpace="preserve"
          style={{ whiteSpace: "pre" }}
          fontSize="6"
          fontWeight="600"
          letterSpacing="0em"
        >
          <tspan x="571.932" y="396.682">
            klasyfikowanie i{" "}
          </tspan>
          <tspan x="576.408" y="403.682">
            definiowanie
          </tspan>
        </text>
      </g>
      <g id="obraz" className="dashed-box">
        <path id="ramka_13" d="M710 72H652V42H710V72Z" fill="#00A8FF" />
        <path
          id="ramka_14"
          d="M709.033 71.0625H653.45V43.4062H709.033V71.0625Z"
          fill="#00A8FF"
          stroke="#FFF9F7"
          strokeDasharray="1 1"
        />
        <text
          id="obraz_2"
          fill="#FFF9F7"
          xmlSpace="preserve"
          style={{ whiteSpace: "pre" }}
          fontSize="6"
          fontWeight="600"
          letterSpacing="0em"
        >
          <tspan x="672.748" y="59.2216">
            obraz
          </tspan>
        </text>
      </g>
      <g id="widzenie_jako_myslenie" className="dashed-box">
        <path id="ramka_15" d="M341 412H284V382H341V412Z" fill="#00A8FF" />
        <path
          id="ramka_16"
          d="M340.05 411.062H285.425V383.406H340.05V411.062Z"
          fill="#00A8FF"
          stroke="#FFF9F7"
          strokeDasharray="1 1"
        />
        <text
          id="widzenie jako myslenie"
          fill="#FFF9F7"
          xmlSpace="preserve"
          style={{ whiteSpace: "pre" }}
          fontSize="6"
          fontWeight="600"
          letterSpacing="0em"
        >
          <tspan x="299.247" y="395.222">
            widzenie{" "}
          </tspan>
          <tspan x="292.562" y="402.222">
            jako my&#x15b;lenie
          </tspan>
        </text>
      </g>
      <Block id="metafora" data-href="/chapter_1/#heading--metafora-i-model">
        <path
          id="ramka_17"
          d="M170 127H115V101H170V127Z"
          fill="#00A8FF"
          stroke="#FFF9F7"
          strokeWidth="2"
          className="double-border"
        />
        <text
          id="metafora_2"
          fill="#FFF9F7"
          xmlSpace="preserve"
          style={{ whiteSpace: "pre" }}
          fontSize="6"
          fontWeight="600"
          letterSpacing="0em"
        >
          <tspan x="129.881" y="115.951">
            metafora
          </tspan>
        </text>
      </Block>
      <Block
        id="porzadek_w_typografi"
        data-href="/chapter_3/#heading--typograficzny-porzdek"
      >
        <path
          id="ramka_18"
          d="M538 187H483V154H538V187Z"
          fill="#00A8FF"
          stroke="#FFF9F7"
          strokeWidth="2"
          className="double-border"
        />
        <text
          id="porzadek w typografii"
          fill="#FFF9F7"
          xmlSpace="preserve"
          style={{ whiteSpace: "pre" }}
          fontSize="6"
          fontWeight="600"
          letterSpacing="0em"
        >
          <tspan x="493.072" y="168.682">
            porz&#x105;dek w{" "}
          </tspan>
          <tspan x="495.979" y="175.682">
            typografii
          </tspan>
        </text>
      </Block>
      <Block id="siec" data-href="/chapter_4/#heading--sie">
        <path
          id="ramka_19"
          d="M538 467H483V441H538V467Z"
          fill="#00A8FF"
          stroke="#FFF9F7"
          strokeWidth="2"
          className="double-border"
        />
        <text
          id="siec_2"
          fill="#FFF9F7"
          xmlSpace="preserve"
          style={{ whiteSpace: "pre" }}
          fontSize="6"
          fontWeight="600"
          letterSpacing="0em"
        >
          <tspan x="504.062" y="455.788">
            sie&#x107;
          </tspan>
        </text>
      </Block>
      <Block id="zbiory" data-href="/chapter_4/#heading--zbiory">
        <path
          id="ramka_20"
          d="M623 524H568V498H623V524Z"
          fill="#00A8FF"
          stroke="#FFF9F7"
          strokeWidth="2"
          className="double-border"
        />
        <text
          id="zbiory_2"
          fill="#FFF9F7"
          xmlSpace="preserve"
          style={{ whiteSpace: "pre" }}
          fontSize="6"
          fontWeight="600"
          letterSpacing="0em"
        >
          <tspan x="585.851" y="512.788">
            zbiory
          </tspan>
        </text>
      </Block>
      <Block
        id="mapowanie"
        data-href="/chapter_4/#heading--mapowanie--okrelanie-relacji-w-przestrzeni"
      >
        <path
          id="ramka_21"
          d="M793 467H738V441H793V467Z"
          fill="#00A8FF"
          stroke="#FFF9F7"
          strokeWidth="2"
          className="double-border"
        />
        <text
          id="mapowanie_2"
          fill="#FFF9F7"
          xmlSpace="preserve"
          style={{ whiteSpace: "pre" }}
          fontSize="6"
          fontWeight="600"
          letterSpacing="0em"
        >
          <tspan x="748.31" y="455.788">
            mapowanie
          </tspan>
        </text>
      </Block>
      <Block
        id="niepewnosc_i_relatywizacja"
        data-href="/chapter_4/#heading--biae-plamy"
      >
        <path
          id="ramka_22"
          d="M879 471H824V438H879V471Z"
          fill="#00A8FF"
          stroke="#FFF9F7"
          strokeWidth="2"
          className="double-border"
        />
        <text
          id="niepewnosc i relatywizacja"
          fill="#FFF9F7"
          xmlSpace="preserve"
          style={{ whiteSpace: "pre" }}
          fontSize="6"
          fontWeight="600"
          letterSpacing="0em"
        >
          <tspan x="832.681" y="452.682">
            niepewno&#x15b;&#x107; &#10;
          </tspan>
          <tspan x="830.264" y="459.682">
            i relatywizacja
          </tspan>
        </text>
      </Block>
      <Block id="atlas" data-href="/chapter_4/#heading--atlas">
        <path
          id="ramka_23"
          d="M538 524H483V498H538V524Z"
          fill="#00A8FF"
          stroke="#FFF9F7"
          strokeWidth="2"
          className="double-border"
        />
        <text
          id="atlas_2"
          fill="#FFF9F7"
          xmlSpace="preserve"
          style={{ whiteSpace: "pre" }}
          fontSize="6"
          fontWeight="600"
          letterSpacing="0em"
        >
          <tspan x="502.951" y="512.788">
            atlas
          </tspan>
        </text>
      </Block>
      <Block
        id="czytelnosc_czytalnosc"
        data-href="/chapter_3/#heading--czytelnoczytalno"
      >
        <path
          id="ramka_24"
          d="M623 243H568V210H623V243Z"
          fill="#00A8FF"
          stroke="#FFF9F7"
          strokeWidth="2"
          className="double-border"
        />
        <text
          id="czytelnosc / czytalnosc"
          fill="#FFF9F7"
          xmlSpace="preserve"
          style={{ whiteSpace: "pre" }}
          fontSize="6"
          fontWeight="600"
          letterSpacing="0em"
        >
          <tspan x="577.261" y="224.682">
            czytelno&#x15b;&#x107; /{" "}
          </tspan>
          <tspan x="579.18" y="231.682">
            czytalno&#x15b;&#x107;
          </tspan>
        </text>
      </Block>
      <Block
        id="czytelnosc_czytalnosc_2"
        data-href="/chapter_3/#heading--jzyk-wizualny"
      >
        <path
          id="ramka_25"
          d="M793 131H738V98H793V131Z"
          fill="#00A8FF"
          stroke="#FFF9F7"
          strokeWidth="2"
          className="double-border"
        />
        <text
          id="jezyk wizualny"
          fill="#FFF9F7"
          xmlSpace="preserve"
          style={{ whiteSpace: "pre" }}
          fontSize="6"
          fontWeight="600"
          letterSpacing="0em"
        >
          <tspan x="757.318" y="112.682">
            j&#x119;zyk{" "}
          </tspan>
          <tspan x="752.194" y="119.682">
            wizualny
          </tspan>
        </text>
      </Block>
      <Block id="model" data-href="/chapter_1/#anchor__model">
        <path
          id="ramka_26"
          d="M85 184H30V158H85V184Z"
          fill="#00A8FF"
          stroke="#FFF9F7"
          strokeWidth="2"
          className="double-border"
        />
        <text
          id="model_2"
          fill="#FFF9F7"
          xmlSpace="preserve"
          style={{ whiteSpace: "pre" }}
          fontSize="6"
          fontWeight="600"
          letterSpacing="0em"
        >
          <tspan x="49.0231" y="172.951">
            model
          </tspan>
        </text>
      </Block>
      <Block
        id="funkcje_obrazu"
        data-href="/chapter_1/#heading--funkcje-obrazw"
      >
        <path
          id="ramka_27"
          d="M170 187H115V154H170V187Z"
          fill="#00A8FF"
          stroke="#FFF9F7"
          strokeWidth="2"
          className="double-border"
        />
        <text
          id="funkcje obrazu"
          fill="#FFF9F7"
          xmlSpace="preserve"
          style={{ whiteSpace: "pre" }}
          fontSize="6"
          fontWeight="600"
          letterSpacing="0em"
        >
          <tspan x="132.274" y="168.889">
            funkcje{" "}
          </tspan>
          <tspan x="132.883" y="175.889">
            obrazu
          </tspan>
        </text>
      </Block>
      <Block
        id="poszukiwanie_formy"
        data-href="/chapter_2/#heading--poszukiwania-formy"
      >
        <path
          id="ramka_28"
          d="M170 414H115V381H170V414Z"
          fill="#00A8FF"
          stroke="#FFF9F7"
          strokeWidth="2"
          className="double-border"
        />
        <text
          id="poszukiwanie formy"
          fill="#FFF9F7"
          xmlSpace="preserve"
          style={{ whiteSpace: "pre" }}
          fontSize="6"
          fontWeight="600"
          letterSpacing="0em"
        >
          <tspan x="123.031" y="395.682">
            poszukiwanie{" "}
          </tspan>
          <tspan x="134.554" y="402.682">
            formy
          </tspan>
        </text>
      </Block>
      <Block
        id="wizualny_alfabetyzm"
        data-href="/chapter_2/#heading--wizualny-alfabetyzm"
      >
        <path
          id="ramka_29"
          d="M340 471H285V438H340V471Z"
          fill="#00A8FF"
          stroke="#FFF9F7"
          strokeWidth="2"
          className="double-border"
        />
        <text
          id="wizualny alfabetyzm"
          fill="#FFF9F7"
          xmlSpace="preserve"
          style={{ whiteSpace: "pre" }}
          fontSize="6"
          fontWeight="600"
          letterSpacing="0em"
        >
          <tspan x="300.194" y="452.682">
            wizualny{" "}
          </tspan>
          <tspan x="296.784" y="459.682">
            alfabetyzm
          </tspan>
        </text>
      </Block>
      <Block
        id="schemat_diagram_wykres_infografika"
        data-href="/chapter_1/#anchor__pojecia"
      >
        <path
          id="ramka_30"
          d="M255 138H200V90H255V138Z"
          fill="#00A8FF"
          stroke="#FFF9F7"
          strokeWidth="2"
          className="double-border"
        />
        <text
          id="schemat, diagram, wykres, infografika"
          fill="#FFF9F7"
          xmlSpace="preserve"
          style={{ whiteSpace: "pre" }}
          fontSize="6"
          fontWeight="600"
          letterSpacing="0em"
        >
          <tspan x="213.877" y="105.453">
            schemat,{" "}
          </tspan>
          <tspan x="214.583" y="112.453">
            diagram,{" "}
          </tspan>
          <tspan x="215.934" y="119.453">
            wykres,{" "}
          </tspan>
          <tspan x="211.639" y="126.453">
            infografika
          </tspan>
        </text>
      </Block>
      <Block
        id="obraz_i_wiedza"
        data-href="/chapter_1/#heading--czy-to-jest-jeszcze-sztuka"
      >
        <path
          id="ramka_31"
          d="M199.5 188V188.5H200H255H255.5V188V154V153.5H255H200H199.5V154V188Z"
          fill="#00A8FF"
          stroke="#FFF9F7"
        />
        <text
          id="obraz i wiedza"
          fill="#FFF9F7"
          xmlSpace="preserve"
          style={{ whiteSpace: "pre" }}
          fontSize="6"
          fontWeight="600"
          letterSpacing="0em"
        >
          <tspan x="218.219" y="169.337">
            obraz &#10;
          </tspan>
          <tspan x="215.351" y="176.337">
            i wiedza
          </tspan>
        </text>
      </Block>
      <Block
        id="w_humanistyce"
        data-href="/chapter_1/#heading--wizualizacja-w-humanistyce"
      >
        <path
          id="ramka_32"
          d="M199.5 241V241.5H200H255H255.5V241V214V213.5H255H200H199.5V214V241Z"
          fill="#00A8FF"
          stroke="#FFF9F7"
        />
        <text
          id="w humanistyce"
          fill="#FFF9F7"
          xmlSpace="preserve"
          style={{ whiteSpace: "pre" }}
          fontSize="6"
          fontWeight="600"
          letterSpacing="0em"
        >
          <tspan x="205.086" y="229.682">
            w humanistyce
          </tspan>
        </text>
      </Block>
      <Block id="historia" data-href="/chapter_1/#heading--budowanie-przekazu">
        <path
          id="ramka_33"
          d="M114.5 241V241.5H115H170H170.5V241V214V213.5H170H115H114.5V214V241Z"
          fill="#00A8FF"
          stroke="#FFF9F7"
        />
        <text
          id="historia_2"
          fill="#FFF9F7"
          xmlSpace="preserve"
          style={{ whiteSpace: "pre" }}
          fontSize="6"
          fontWeight="600"
          letterSpacing="0em"
        >
          <tspan x="131.09" y="229.682">
            historia
          </tspan>
        </text>
      </Block>
      <Block
        id="isotype"
        data-href="/chapter_2/#heading--obraz-syntetyczny--isotype"
      >
        <path
          id="ramka_34"
          d="M114.5 354V354.5H115H170H170.5V354V327V326.5H170H115H114.5V327V354Z"
          fill="#00A8FF"
          stroke="#FFF9F7"
        />
        <text
          id="Isotype"
          fill="#FFF9F7"
          xmlSpace="preserve"
          style={{ whiteSpace: "pre" }}
          fontSize="6"
          fontWeight="600"
          letterSpacing="0em"
        >
          <tspan x="131.184" y="342.682">
            Isotype
          </tspan>
        </text>
      </Block>
      <Block
        id="analiza_i_synteza"
        data-href="/chapter_2/#heading--od-analizy-do-syntezy--i-z-powrotem"
      >
        <path
          id="ramka_35"
          d="M199.5 414V414.5H200H255H255.5V414V381V380.5H255H200H199.5V381V414Z"
          fill="#00A8FF"
          stroke="#FFF9F7"
        />
        <text
          id="analiza i synteza"
          fill="#FFF9F7"
          xmlSpace="preserve"
          style={{ whiteSpace: "pre" }}
          fontSize="6"
          fontWeight="600"
          letterSpacing="0em"
        >
          <tspan x="216.011" y="396.182">
            analiza &#10;
          </tspan>
          <tspan x="213.963" y="403.182">
            i synteza
          </tspan>
        </text>
      </Block>
      <Block
        id="recepcja_i_percepcja"
        data-href="/chapter_2/#heading--recepcja-i-percepcja"
      >
        <path
          id="ramka_36"
          d="M369.5 414V414.5H370H425H425.5V414V381V380.5H425H370H369.5V381V414Z"
          fill="#00A8FF"
          stroke="#FFF9F7"
        />
        <text
          id="recepcja i percepcja"
          fill="#FFF9F7"
          xmlSpace="preserve"
          style={{ whiteSpace: "pre" }}
          fontSize="6"
          fontWeight="600"
          letterSpacing="0em"
        >
          <tspan x="383.734" y="396.182">
            recepcja &#10;
          </tspan>
          <tspan x="381.077" y="403.182">
            i percepcja
          </tspan>
        </text>
      </Block>
      <Block
        id="recepcja_i_percepcja_2"
        data-href="/chapter_2/#heading--myliobrazy-i-mylisowa"
      >
        <path
          id="ramka_37"
          d="M369.5 471V471.5H370H425H425.5V471V438V437.5H425H370H369.5V438V471Z"
          fill="#00A8FF"
          stroke="#FFF9F7"
        />
        <text
          id="mysl jako obraz i slowo"
          fill="#FFF9F7"
          xmlSpace="preserve"
          style={{ whiteSpace: "pre" }}
          fontSize="6"
          fontWeight="600"
          letterSpacing="0em"
        >
          <tspan x="383.494" y="453.182">
            my&#x15b;l jako{" "}
          </tspan>
          <tspan x="377.951" y="460.182">
            obraz i s&#x142;owo
          </tspan>
        </text>
      </Block>
      <Block id="w_rysunku" data-href="/chapter_2/#heading--mdro-w-rysunku">
        <path
          id="ramka_38"
          d="M199.5 467V467.5H200H255H255.5V467V441V440.5H255H200H199.5V441V467Z"
          fill="#00A8FF"
          stroke="#FFF9F7"
        />
        <text
          id="w rysunku"
          fill="#FFF9F7"
          xmlSpace="preserve"
          style={{ whiteSpace: "pre" }}
          fontSize="6"
          fontWeight="600"
          letterSpacing="0em"
        >
          <tspan x="211.944" y="456.552">
            w rysunku
          </tspan>
        </text>
      </Block>
      <Block id="notowanie" data-href="/chapter_2/#anchor__notowanie">
        <path
          id="ramka_39"
          d="M199.5 524V524.5H200H255H255.5V524V498V497.5H255H200H199.5V498V524Z"
          fill="#00A8FF"
          stroke="#FFF9F7"
        />
        <text
          id="notowanie_2"
          fill="#FFF9F7"
          xmlSpace="preserve"
          style={{ whiteSpace: "pre" }}
          fontSize="6"
          fontWeight="600"
          letterSpacing="0em"
        >
          <tspan x="211.824" y="513.552">
            notowanie
          </tspan>
        </text>
      </Block>
      <Block
        id="architektura_informacji"
        data-href="/chapter_1/#heading--budowanie-przekazu"
      >
        <path
          id="ramka_40"
          d="M284.5 188V188.5H285H340H340.5V188V154V153.5H340H285H284.5V154V188Z"
          fill="#00A8FF"
          stroke="#FFF9F7"
        />
        <text
          id="architektura informacji"
          fill="#FFF9F7"
          xmlSpace="preserve"
          style={{ whiteSpace: "pre" }}
          fontSize="6"
          fontWeight="600"
          letterSpacing="0em"
        >
          <tspan x="294.591" y="169.337">
            architektura{" "}
          </tspan>
          <tspan x="297.84" y="176.337">
            informacji
          </tspan>
        </text>
      </Block>
      <Block
        id="kultura_wizualna_wspolczesnie"
        data-href="/chapter_1/#heading--wspczesna-kultura-wizualna"
      >
        <path
          id="ramka_41"
          d="M340 135H284V93H340V135Z"
          fill="#00A8FF"
          stroke="#FFF9F7"
        />
        <text
          id="kultura wizualna wspolczesnie"
          fill="#FFF9F7"
          xmlSpace="preserve"
          style={{ whiteSpace: "pre" }}
          fontSize="6"
          fontWeight="600"
          letterSpacing="0em"
        >
          <tspan x="302.082" y="108.824">
            kultura{" "}
          </tspan>
          <tspan x="299.387" y="115.824">
            wizualna{" "}
          </tspan>
          <tspan x="292.423" y="122.824">
            wsp&#xf3;&#x142;cze&#x15b;nie
          </tspan>
        </text>
      </Block>
      <Block
        id="projektowanie_graficzne"
        data-href="/chapter_1/#heading--projektowanie--cele-i-etos"
      >
        <path
          id="ramka_42"
          d="M425 131H369V97H425V131Z"
          fill="#00A8FF"
          stroke="#FFF9F7"
        />
        <text
          id="projektowanie graficzne"
          fill="#FFF9F7"
          xmlSpace="preserve"
          style={{ whiteSpace: "pre" }}
          fontSize="6"
          fontWeight="600"
          letterSpacing="0em"
        >
          <tspan x="376.632" y="112.249">
            projektowanie{" "}
          </tspan>
          <tspan x="383.552" y="119.249">
            graficzne
          </tspan>
        </text>
      </Block>
      <Block id="notacja" data-href="/chapter_3/#heading--notacja">
        <path
          id="ramka_43"
          d="M539 127H483V100H539V127Z"
          fill="#00A8FF"
          stroke="#FFF9F7"
        />
        <text
          id="notacja_2"
          fill="#FFF9F7"
          xmlSpace="preserve"
          style={{ whiteSpace: "pre" }}
          fontSize="6"
          fontWeight="600"
          letterSpacing="0em"
        >
          <tspan x="500.493" y="115.809">
            notacja
          </tspan>
        </text>
      </Block>
      <Block id="linia" data-href="/chapter_3/#heading--krelenie-linii">
        <path
          id="ramka_44"
          d="M624 71H568V43H624V71Z"
          fill="#00A8FF"
          stroke="#FFF9F7"
        />
        <text
          id="linia_2"
          fill="#FFF9F7"
          xmlSpace="preserve"
          style={{ whiteSpace: "pre" }}
          fontSize="6"
          fontWeight="600"
          letterSpacing="0em"
        >
          <tspan x="590.348" y="59.3138">
            linia
          </tspan>
        </text>
      </Block>
      <Block id="interfejs" data-href="/chapter_3/#heading--interfejs-tekstu">
        <path
          id="ramka_45"
          d="M624 184H568V156H624V184Z"
          fill="#00A8FF"
          stroke="#FFF9F7"
        />
        <text
          id="interfejs_2"
          fill="#FFF9F7"
          xmlSpace="preserve"
          style={{ whiteSpace: "pre" }}
          fontSize="6"
          fontWeight="600"
          letterSpacing="0em"
        >
          <tspan x="584.181" y="172.314">
            interfejs
          </tspan>
        </text>
      </Block>
      <Block
        id="ksiazka_jako_przedmiot"
        data-href="/chapter_3/#heading--ksika-jest-przedmiotem"
      >
        <path
          id="ramka_46"
          d="M709 189H653V153H709V189Z"
          fill="#00A8FF"
          stroke="#FFF9F7"
        />
        <text
          id="ksiazka jako przedmiot"
          fill="#FFF9F7"
          xmlSpace="preserve"
          style={{ whiteSpace: "pre" }}
          fontSize="6"
          fontWeight="600"
          letterSpacing="0em"
        >
          <tspan x="663.556" y="169.851">
            ksi&#x105;&#x17c;ka jako{" "}
          </tspan>
          <tspan x="666.436" y="176.851">
            przedmiot
          </tspan>
        </text>
      </Block>
      <Block
        id="ewolucja_czytania"
        data-href="/chapter_3/#heading--przemiany-lektury"
      >
        <path
          id="ramka_47"
          d="M709 244H653V208H709V244Z"
          fill="#00A8FF"
          stroke="#FFF9F7"
        />
        <text
          id="ewolucja czytania"
          fill="#FFF9F7"
          xmlSpace="preserve"
          style={{ whiteSpace: "pre" }}
          fontSize="6"
          fontWeight="600"
          letterSpacing="0em"
        >
          <tspan x="668.343" y="224.518">
            ewolucja{" "}
          </tspan>
          <tspan x="668.906" y="231.518">
            czytania
          </tspan>
        </text>
      </Block>
      <Block
        id="mechanizmy_porzodkowania"
        data-href="/chapter_4/#heading--mechanizmy-klasyfikacji"
      >
        <path
          id="ramka_48"
          d="M624 471H568V437H624V471Z"
          fill="#00A8FF"
          stroke="#FFF9F7"
        />
        <text
          id="ewolucja czytania_2"
          fill="#FFF9F7"
          xmlSpace="preserve"
          style={{ whiteSpace: "pre" }}
          fontSize="6"
          fontWeight="600"
          letterSpacing="0em"
        >
          <tspan x="577.578" y="452.182">
            mechanizmy{" "}
          </tspan>
          <tspan x="573.828" y="459.182">
            porz&#x105;dkowania
          </tspan>
        </text>
      </Block>
      <Block
        id="graficzne_definicje"
        data-href="/chapter_4/#heading--graficzne-definicje"
      >
        <path
          id="ramka_49"
          d="M624 584H568V550H624V584Z"
          fill="#00A8FF"
          stroke="#FFF9F7"
        />
        <text
          id="ewolucja czytania_3"
          fill="#FFF9F7"
          xmlSpace="preserve"
          style={{ whiteSpace: "pre" }}
          fontSize="6"
          fontWeight="600"
          letterSpacing="0em"
        >
          <tspan x="582.307" y="565.182">
            graficzne{" "}
          </tspan>
          <tspan x="583.517" y="572.182">
            definicje
          </tspan>
        </text>
      </Block>
      <Block id="esej_wizualny" data-href="/chapter_4/#heading--esej-wizualny">
        <path
          id="ramka_50"
          d="M538 584H482V550H538V584Z"
          fill="#00A8FF"
          stroke="#FFF9F7"
        />
        <text
          id="ewolucja czytania_4"
          fill="#FFF9F7"
          xmlSpace="preserve"
          style={{ whiteSpace: "pre" }}
          fontSize="6"
          fontWeight="600"
          letterSpacing="0em"
        >
          <tspan x="503.271" y="565.182">
            esej &#10;
          </tspan>
          <tspan x="497.194" y="572.182">
            wizualny
          </tspan>
        </text>
      </Block>
      <Block
        id="wzglednosc_czasu"
        data-href="/chapter_4/#anchor__wzglednosc-czasu"
      >
        <path
          id="ramka_51"
          d="M709 584H653V550H709V584Z"
          fill="#00A8FF"
          stroke="#FFF9F7"
        />
        <text
          id="wzglednosc czasu"
          fill="#FFF9F7"
          xmlSpace="preserve"
          style={{ whiteSpace: "pre" }}
          fontSize="6"
          fontWeight="600"
          letterSpacing="0em"
        >
          <tspan x="663.495" y="565.182">
            wzgl&#x119;dno&#x15b;&#x107;{" "}
          </tspan>
          <tspan x="672.39" y="572.182">
            czasu
          </tspan>
        </text>
      </Block>
      <Block id="test_vs_obraz" data-href="/chapter_3/#heading--tekst-vs-obraz">
        <path
          id="ramka_52"
          d="M624 131H568V97H624V131Z"
          fill="#00A8FF"
          stroke="#FFF9F7"
        />
        <text
          id="tekst vs. obraz"
          fill="#FFF9F7"
          xmlSpace="preserve"
          style={{ whiteSpace: "pre" }}
          fontSize="6"
          fontWeight="600"
          letterSpacing="0em"
        >
          <tspan x="588.107" y="112.842">
            tekst &#10;
          </tspan>
          <tspan x="583.041" y="119.842">
            vs. obraz
          </tspan>
        </text>
      </Block>
      <Block
        id="czas_jako_przestrzen"
        data-href="/chapter_4/#heading--czas-jako-przestrze"
      >
        <path
          id="ramka_53"
          d="M709 471H653V437H709V471Z"
          fill="#00A8FF"
          stroke="#FFF9F7"
        />
        <text
          id="czas jako przestrzen"
          fill="#FFF9F7"
          xmlSpace="preserve"
          style={{ whiteSpace: "pre" }}
          fontSize="6"
          fontWeight="600"
          letterSpacing="0em"
        >
          <tspan x="667.745" y="452.842">
            czas jako{" "}
          </tspan>
          <tspan x="665.545" y="459.842">
            przestrze&#x144;
          </tspan>
        </text>
      </Block>
      <Block id="os_czasu" data-href="/chapter_4/#heading--budowa-osi">
        <path
          id="ramka_54"
          d="M709 524H653V496H709V524Z"
          fill="#00A8FF"
          stroke="#FFF9F7"
        />
        <text
          id="os czasu"
          fill="#FFF9F7"
          xmlSpace="preserve"
          style={{ whiteSpace: "pre" }}
          fontSize="6"
          fontWeight="600"
          letterSpacing="0em"
        >
          <tspan x="668.419" y="512.314">
            o&#x15b; czasu
          </tspan>
        </text>
      </Block>
      <Block
        id="konwencje_wizualne"
        data-href="/chapter_4/#heading--konwencje-wizualizacji-przestrzeni"
      >
        <path
          id="ramka_55"
          d="M794 528H738V494H794V528Z"
          fill="#00A8FF"
          stroke="#FFF9F7"
        />
        <text
          id="konwencje wizualne"
          fill="#FFF9F7"
          xmlSpace="preserve"
          style={{ whiteSpace: "pre" }}
          fontSize="6"
          fontWeight="600"
          letterSpacing="0em"
        >
          <tspan x="750.589" y="509.842">
            konwencje{" "}
          </tspan>
          <tspan x="753.331" y="516.842">
            wizualne
          </tspan>
        </text>
      </Block>
      <Block
        id="mapa_i_narracja"
        data-href="/chapter_4/#heading--opowie-a-mapa"
      >
        <path
          id="ramka_56"
          d="M879 528H823V494H879V528Z"
          fill="#00A8FF"
          stroke="#FFF9F7"
        />
        <text
          id="mapa i narracja"
          fill="#FFF9F7"
          xmlSpace="preserve"
          style={{ whiteSpace: "pre" }}
          fontSize="6"
          fontWeight="600"
          letterSpacing="0em"
        >
          <tspan x="842.489" y="509.842">
            mapa &#10;
          </tspan>
          <tspan x="837.86" y="516.842">
            i narracja
          </tspan>
        </text>
      </Block>
      <Block
        id="mapa_jako_obraz_swiata"
        data-href="/chapter_4/#heading--obraz-wiata"
      >
        <path
          id="ramka_57"
          d="M879 414H823V380H879V414Z"
          fill="#00A8FF"
          stroke="#FFF9F7"
        />
        <text
          id="mapa jako obraz swiata"
          fill="#FFF9F7"
          xmlSpace="preserve"
          style={{ whiteSpace: "pre" }}
          fontSize="6"
          fontWeight="600"
          letterSpacing="0em"
        >
          <tspan x="836.518" y="395.249">
            mapa jako{" "}
          </tspan>
          <tspan x="832.695" y="402.249">
            obraz &#x15b;wiata
          </tspan>
        </text>
      </Block>
      <Block id="mapa_mentalna" data-href="/chapter_4/#heading--mapa-mentalna">
        <path
          id="ramka_58"
          d="M879 358H823V324H879V358Z"
          fill="#00A8FF"
          stroke="#FFF9F7"
        />
        <text
          id="mapa mentalna"
          fill="#FFF9F7"
          xmlSpace="preserve"
          style={{ whiteSpace: "pre" }}
          fontSize="6"
          fontWeight="600"
          letterSpacing="0em"
        >
          <tspan x="843.233" y="339.249">
            mapa{" "}
          </tspan>
          <tspan x="837.751" y="346.249">
            mentalna
          </tspan>
        </text>
      </Block>
    </>
  )
}

export default Blocks
